import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Footer, Navbar } from "../../../components";
import OtpInput from "react18-otp-input";
import { req } from "../../../requests";
import Button1 from "./components/Button";
import FooterNew from "./components/Footer";
import { kavanLogoChatbot } from "../../../assets/index";



const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const number = urlParams.get("number");
  const email = urlParams.get("email");
 
  const preferences = urlParams.get("preferences");
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(60);
  const[resendVisible,setResendVisible] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (currentTime > 0) {
      timerRef.current = setTimeout(() => {
        setCurrentTime((prevTime) => prevTime - 1); // Use functional state update
      }, 1000);
    } else {
      setResendVisible(true);
      clearTimeout(timerRef.current); // Clear timeout when timer ends
    }

    return () => clearTimeout(timerRef.current); // Cleanup on unmount or before next effect
  }, [currentTime]);



  return (
    <div>
      {/* <Navbar /> */}
     
      <div className="kavan__navbar-links_logo" style={{marginLeft:40,marginTop:20}}>
    <img src={kavanLogoChatbot} alt="Logo" onClick={() => window.location.href = "https://www.kavanhealth.com"}/>
    </div>
      <div className="kwn-fill_out_form-main_container">
        <div className="kwn-fill_out_form-title_container">
          <h1 className="otpHeading">Verifica tu número de teléfono</h1>
          <h2 style={{fontSize:15,fontFamily: 'Satoshi, sans-serif',color:"#120D30"}}>
            Ingresa el código de 4 dígitos enviado a 
          </h2>
        <div style={{display:'flex',flexDirection:'row'}}>
        <h2 style={{fontSize:15}}>
         
         {decodeURIComponent(number)} <span style={{fontSize:15,color:"#016039",cursor:"pointer"}} onClick={() => navigate(-1)}>Editar {number ? 'numero' : 'email'}</span>
         </h2>

         
        </div>
        </div>
        <div className="kwn-verify_email-otp_container">
          <OtpInput
            inputStyle="kwn-verify_email-otp_style"
            numInputs={4}
            onChange={(value) => setOtp(value)}
            separator={<span> </span>}
            isInputNum={true}
            shouldAutoFocus
            value={otp}
            containerStyle="kwn-verify_email-otp_container_style"
          />
        </div>
        <h3 className="margin-in" style={{ margin: "10px", color: "red" }}>{error}</h3>
        
       {resendVisible && <h2 style={{fontSize:15,color:"#016039",cursor:"pointer"}}>
         
        Reenviar código vía SMS
         </h2>}
        <h2 className="curr-time-text" style={{ fontSize: 13, color: "#120D30",fontFamily: 'Satoshi, sans-serif',marginTop:30}}>
        Reenvío del Código en {currentTime}s
        </h2>
        <div className="kwn-verify_email-Button_container_centered">
          <div
            className="btn-verify-container"
            style={{
              marginTop: "3rem",
              marginRight: "0",
              width: 300,
              opacity: otp.length == 4 ? 1 : 0.4,
              cursor: otp.length == 4 ? "default" : "pointer",
            }}
          >
            <Button1
            backgroundColor={"#04494B"}
              onClick={async () => {
                // if(!preferences){
                //   setTimeout(() => {
                //     navigate(`/chatbotStart?email=${email}&number=${encodeURIComponent(number)}`)
                //   }, 500);
                // }
                // else{
                //   setTimeout(() => {
                //     window.location.href = `https://kavan-web-liard.vercel.app?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`;
                //     // window.location.href = `http://localhost:3000?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`;

                //   }, 500);

                // }
                
                if (otp.length != 4) return;
                setLoading(true);
                console.log("I am inside this divvv");
                await req(
                  "POST",
                  "/user/verify",
                  {
                    number: number
                      ? `${decodeURIComponent(number).toLowerCase().trim()}`
                      : undefined,
                    email: email ? email.toLowerCase().trim() : undefined,
                    otp,
                    device: { id: "web", deviceToken: "MockToken" },
                  },
                  () => {
                    setError("Invalid Token");
                    setLoading(false);
                  },
                  ({ token, refreshToken, accountLink }) => {
                   try {console.log("Hi! Verify?");
                    localStorage.setItem("kawan_accessToken", token);
                    localStorage.setItem("kawan_refreshToken", refreshToken);
                    const loginSet = (
                      kawan_accessToken,
                      kawan_refreshToken
                    ) => {
                      document.cookie = `kawan_accessToken=${kawan_accessToken};kawan_refreshToken=${kawan_refreshToken};domain=.kavanhealth.com`;
                    };
                    loginSet(token, refreshToken);
                    // console.log("ACCOUNT LINK =>", accountLink.url)
                    // if(accountLink?.url) window.location.href = accountLink.url;
                    console.log("I am reached here....")
                    
                    if(email?.includes?.("talent-class") && !window.location.href.includes("businessDashboard")){
                      console.log("insideee business login")
                      window.location.href = `https://app.kavanhealth.com/mentalHealthIndex?businessLogin&email=${email}`;
                      // window.location.href = `http://localhost:3000/mentalHealthIndex?businessLogin`;
                      return;
                    }else if(email?.includes?.("talent-class") && window.location.href.includes("businessDashboard")){
                      window.location.href = `http://app.kavanhealth.com?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`
                      console.log("insideee elssssss")
                    }
                    if(!preferences){
                      console.log("inside not preferences")
                      setTimeout(() => {
                        navigate(`/chatbotStart?email=${email}&number=${encodeURIComponent(number)}`)
                      }, 500);
                    }
                    else{
                      console.log("inside preferences")

                      setTimeout(() => {
                        window.location.href = `https://app.kavanhealth.com?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`;
                        // window.location.href = `http://localhost:3000?email=${email}&number=${encodeURIComponent(number)?.trim?.()}`;
    
                      }, 500);
    
                    }}catch(e){
                      console.log("errrrrrrrr",e);
                    }
                    // navigate("/choosePofile");
                    // setTimeout(() => {
                    //   navigate(`/chatbotStart?email=${email}&number=${encodeURIComponent(number)}`)
                    // }, 500);
                  }
                );
              }}
            >
              {loading ? "Verificando..." : "Verificar"}
            </Button1>
          </div>
        </div>
      </div>
      {/* <FooterNew /> */}
    </div>
  );
};

export default VerifyEmail;
