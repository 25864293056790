import React from 'react'
import './button.css'
import googleIcon from "../../assets/google.png";
import mailIcon from "../../assets/email.png";

export default function InputButton(props) {
    const shouldDisable = props.disabled === undefined ? false : props.disabled
    return (
        <div onClick={shouldDisable ? () => { } : props.onClick} className='button_container1' style={{borderRadius:props.borderRadius ? props.borderRadius :0, cursor: shouldDisable ? 'auto' : props.cursor, backgroundColor: props?.backgroundColor ? props.backgroundColor : props.border ? '#fff' : '#006039', opacity: shouldDisable ? 0.4 : 1, marginTop: props.marginTop, ...props.style }}>
            {props.image && <image src={googleIcon}/>
            }
            <p style={{color:props.color ? props.color : '#ffffff',fontFamily: 'Satoshi, sans-serif',fontSize:15 }}>{props.children}</p>
        </div>
    )
}

