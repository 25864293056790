import React, { useState } from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { req } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import FooterNew from "./components/Footer";
import TextInput1 from "./components/TextInput";
import Button1 from "./components/Button";
import { kavanLogoChatbot } from "../../../assets/index";
import CustomPhoneInput from "../../../components/phoneInput";
import InputButton from "../../../components/input_button/Button";

export default function SignIn({dashboard}) {
  const { signedUp } = getAllParams();
  const navigate = useNavigate();
  const [error, setError] = useState(""); 
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigateToDashboard = dashboard;

  localStorage.clear();
  // window.location.href = "https://www.kavanhealth.com/log-in"
  // return <></>


  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
 
    {!navigateToDashboard &&<div className="kavan__navbar-links_logo" style={{ marginLeft: 40, marginTop: 20 }}>
      <img src={kavanLogoChatbot} alt="Logo" onClick={() => window.location.href = "https://www.kavanhealth.com"} />
    </div>}
      <div className={`mainDiv`} style={{flex:0.6}}>
    {  !navigateToDashboard && <> <h3 className="headingText" style={{ fontFamily: 'Satoshi' }}>Bienvenido a Kavan Health</h3>
        <h3 className="subHeadingText" style={{ fontFamily: 'Satoshi' }}>Ir a terapia puede ser tu mayor fortaleza.</h3></>}

        <div style={{  alignSelf: 'center' }}>
              {/* <CustomPhoneInput title="Ingresa tu numero de telefono" codeVisible={true} placeholder="Enter Phone Number" phonenumber={phoneNumber} setphonenumber={setPhoneNumber} selectedcode={selectedCode} setselectedcode={setSelectedCode} />
             <div className="inputStyles">
               <h3 className="phoneInputTitleText">Ingresa tu numero de telefono</h3>

            </div> */}
            <CustomPhoneInput title="Correo electrónico empresarial" placeholder="user@company.com" phonenumber={email} setphonenumber={setEmail} />
          


        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40, gap: 30 }}>
          <InputButton backgroundColor="#04494B" borderRadius={10} 
           onClick={async () => {
            let success = true;
            setLoading(true);
            const data = await req(
              "POST",
              "/user/login",
              {
                email: email.toLowerCase().trim(),
                device: {
                  id: "web",
                  deviceToken: "MockToken",
                },
              },
              async (message) => {
                setLoading(false);
                success = false;
                setError(message);
              }
            );
            if (!success) return;
            setLoading(false);
            // if(window.location.href.toLowerCase().includes("patientbusinesssignin/dashboard")){
            if(navigateToDashboard){

              navigate(`/patientVerifyEmail?email=${email}&businessDashboard`);
            }else{
              navigate(`/patientVerifyEmail?email=${email}`);
            }
            
          }}
          >{loading ? "Loading..." : "Iniciar sesión"}</InputButton>
        </div>
        
        <div style={{ marginTop: 70,display:'flex',flexDirection:'column',gap:'10px',justifyContent: 'center', alignItems: 'center', }}>
        <p style={{ marginRight: 5, fontFamily: 'Satoshi',fontSize:'16px',color:"#9E9CAA",textAlign:"center" }}>Al continuar, acepta automáticamente nuestros</p>
          <p style={{ fontFamily: 'Satoshi',textAlign:"center",fontSize:'16px',color:"#9E9CAA" }}><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(
            'https://kavan-assets.s3.eu-west-3.amazonaws.com/KAVAN+HEALTH+S.L.+-+TE%CC%81RMINOS+Y+CONDICIONES+GENERALES+-+PACIENTES+(28.01.2025).pdf',
            '_blank'
          )}> Términos & Condiciones</span>  y  <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(
            'https://kavan-assets.s3.eu-west-3.amazonaws.com/KAVAN_HEALTH_S.L._-_POL%C3%8DTICA_PRIVACIDAD_APP_(23.01.2024).pdf',
            '_blank'
          )}> Política de privacidad</span></p>
        </div>
      </div>
    //   {/* <Footer/> */}
     </GoogleOAuthProvider>





  );

  // return (
  //   <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
  //     <Navbar />
  //     <div className="kwn-fill_out_form-main_container">
  //       <div className="kwn-sign_in-form_container">
  //         <div
  //           style={{ alignItems: "flex-start" }}
  //           className="kwn-fill_out_form-title_container"
  //         >
  //           <h1>
  //             {signedUp
  //               ? "Espere mientras revisamos su solicitud"
  //               : "Bienvenido"}
  //           </h1>
  //           <h2 style={{ textAlign: "left" }}>
  //             Inicie sesión para empezar a utilizar Kavan Health
  //           </h2>
  //         </div>
  //         <TextInput1
  //           type={"text"}
  //           title={"Business Email"}
  //           value={email}
  //           onChange={(ev) => setEmail(ev.target.value)}
  //           placeholder={"user@company.com"}
  //         />
  //       </div>

  //       <div
  //         style={{
  //           color: "red",
  //           display: "flex",
  //           justifyContent: "flex-start",
  //           fontSize: 20,
  //         }}
  //       >
  //         {error}
  //       </div>
  //       <div className="kwn-sign_in-Button_container">
  //         <Button1
  //         style={{padding: '10px'}}
  //           onClick={async () => {
  //             let success = true;
  //             setLoading(true);
  //             const data = await req(
  //               "POST",
  //               "/user/login",
  //               {
  //                 email: email.toLowerCase().trim(),
  //                 device: {
  //                   id: "web",
  //                   deviceToken: "MockToken",
  //                 },
  //               },
  //               async (message) => {
  //                 setLoading(false);
  //                 success = false;
  //                 setError(message);
  //               }
  //             );
  //             if (!success) return;
  //             setLoading(false);
  //             navigate(`/patientVerifyEmail?email=${email}`);
  //           }}
  //         >
  //           {loading ? "Loading..." : "Iniciar sesión"}
  //         </Button1>
  //       </div>
  //       <h3>OR</h3>
  //       <p
  //         style={{ textDecoration: "underline", cursor: "pointer" }}
  //         onClick={() => navigate("/patientSignIn")}
  //       >
  //         Login as a normal user
  //       </p>
  //       <div>
  //         {/* {socialArray.map((item) => { */}
  //         {/* return ( */}
  //         {/* <GoogleLogin
  //           onSuccess={async (credentialResponse) => {
  //             const jwt = credentialResponse.credential;
  //             const decoded = jwt_decode(jwt);
  //             const email = decoded?.email;
  //             const name = decoded?.name;
  //             const result = { email, name };

  //             let success = true;
  //             setLoading(true);
  //             const data = await req(
  //               "POST",
  //               "/user/socialLogin",
  //               {
  //                 email,
  //                 name,
  //                 device: {
  //                   id: "web",
  //                   deviceToken: "MockToken",
  //                 },
  //               },
  //               (message) => {
  //                 setLoading(false);
  //                 success = false;
  //                 setError(message);
  //               }
  //             );
  //             if (!success) return;
  //             setLoading(false);
  //             console.log(data);
  //             console.log("Tokens =>", data);
  //             const {
  //               user,
  //               userAlreadyExisted,
  //               token,
  //               refreshToken,
  //               accountLink,
  //             } = data;
  //             localStorage.setItem("kawan_accessToken", token);
  //             localStorage.setItem("kawan_refreshToken", refreshToken);
  //             if (!userAlreadyExisted) return navigate("/choosePofile");
  //             if (!user.approved) return navigate("/registrationPending");
  //             if (accountLink) return (window.location.href = accountLink.url);
  //             navigate("/dashboard/AdminHome");

  //             console.log(result);
  //           }}
  //           onError={() => {
  //             console.log("Login Failed");
  //           }}
  //         /> */}
  //         {/* ); */}
  //         {/* })} */}
  //       </div>
  //     </div>
  //     {/* <Footer /> */}
  //     <FooterNew />
  //   </GoogleOAuthProvider>
  // );
}
