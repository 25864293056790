import React, { useState } from "react";
import './buttonGroup.css';


const MyButtonGroup = ({setSignInVisible}) => {
    const [personalSelected,setPersonalSelected] = useState(true);
    return (
        <div className="buttonGroupMainDivv">
            <div className={`leftButtonStyles ${personalSelected && 'greenBackColor'}`} onClick={()=>{setSignInVisible(true);setPersonalSelected(true)}}>
                <p className={`buttonGroupTexts ${personalSelected && 'whiteText'}`}>Personal</p>
            </div>
            <div className={`rightButtonStyles ${!personalSelected && 'greenBackColor'}`} onClick={()=>{setSignInVisible(false);setPersonalSelected(false)}}>
                <p className={`buttonGroupTexts ${!personalSelected && 'whiteText'}`}>Empresa</p>
            </div>
        </div>
    );
};

export default MyButtonGroup;
