import React from "react";


const SignInHeading = ({heading,subheading}) =>{
    return(
        <>
       <h3 className="headingText" style={{ fontFamily: 'Satoshi' }}>{heading}</h3>
        <h3 className="subHeadingText" style={{ fontFamily: 'Satoshi' }}>{subheading}</h3>
        </>
    );
}

export default SignInHeading;