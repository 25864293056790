import React, { useState } from "react";
import { Navbar, Footer, TextInput, Button } from "../../../components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { req } from "../../../requests";
import { getAllParams } from "../../../urlParams";
import FooterNew from "./components/Footer";
import TextInput1 from "./components/TextInput";
import Button1 from "./components/Button";
import CustomPhoneInput from "../../../components/phoneInput";
// import TextInput from "./components/TextInput";
import InputButton from "../../../components/input_button/Button";
import logo from "../../../assets/downarrow.png";
import GoogleButton from "../../../components/GoogleButton/Button";
import GooglButtonIcon from "../../../assets/google.png";
import EmailIcon from "../../../assets/email.png";
// import { kavanLogoChatbot } from "../../../assets/index";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.css';


export default function SignIn() {
  const { signedUp } = getAllParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+34");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [buttons, setButton] = useState(false);

  // const [number,setNumber] = useState();
  localStorage.clear();
  // window.location.href = "https://www.kavanhealth.com/log-in"
  // return <></>

  return (
    <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
   
      {/* <div className="kavan__navbar-links_logo" style={{ marginLeft: 40, marginTop: 20 }}>
        <img src={kavanLogoChatbot} alt="Logo" onClick={() => window.location.href = "https://www.kavanhealth.com"} />
      </div> */}
      <div className="mainDiv" style={{flex:0.6}}>


        <div style={{  alignSelf: 'center' }}>
          {!continueWithEmail &&
            //  <CustomPhoneInput title="Ingresa tu numero de telefono" codeVisible={true} placeholder="Enter Phone Number" phonenumber={phoneNumber} setphonenumber={setPhoneNumber} selectedcode={selectedCode} setselectedcode={setSelectedCode} />
            <div className="inputStyles">
              <h3 className="phoneInputTitleText">Ingresa tu numero de telefono</h3>
            
              <PhoneInput
                country={'es'}
                value={phoneNumber}
                onChange={phone => { setPhoneNumber(phone); console.log("changed number is ", phone) }}
                inputStyle={{
                  height: '60px',
                  // width:'400px',            
                  width: '100%',
                  fontSize: '16px',            
                  // padding: '0 15px',            
                  backgroundColor: 'transparent',
                  // border: '2px solid rgb(7, 7, 7)', 
                  border: '2px solidrgb(7, 7, 7)', 
                  borderRadius: '10px',
                  // marginRight:''  
                  // color: '#333',              
                }}
                containerStyle={{
                  // width: '100%',                 
                  // maxWidth: '320px',              
                  margin: '20px auto',           
                  backgroundColor: 'transparent',
                  padding: '0',                  
                }}
                buttonClass={{
                  width: '66px',
                  backgroundColor: 'green',
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                }}
                dropdownStyle={{
                  backgroundColor: 'rgb(199, 214, 202)', 
                  borderRadius: '5px',       
                  color: '#fff',             
                }}

              />
            </div>

          }

          {continueWithEmail && <CustomPhoneInput title="Ingresa tu numero de telefono" placeholder="Enter Email" phonenumber={email} setphonenumber={setEmail} selectedcode={selectedCode} setselectedcode={setSelectedCode} />}

        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40, gap: 30 }}>
          <InputButton backgroundColor="#ffffff" color="#04494B" borderRadius={10} onClick={
            async () => {
              if (!phoneNumber) {
                return;
              }
              let success = true;
              setLoading(true);
              console.log("phone number we are sending is ", phoneNumber);
              const data = await req(
                "POST",
                "/user/numberLogin",
                {
                  number: `+${phoneNumber.toLowerCase().trim()}`,
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },
                  whatsapp: true,
                },
                async (message) => {
                  setLoading(false);
                  success = false;
                  setError(message);
                }
              );
              if (!success) return;
              setLoading(false);
              console.log("email is ", email);
              console.log(data);


              if (!email.trim()) {
                console.log(`maxxxxx ${encodeURIComponent(selectedCode)}${phoneNumber}`)
                navigate(`/patientVerifyEmail?number=${encodeURIComponent('+')}${phoneNumber}`);
              }
              else {
                navigate(`/patientVerifyEmail?email=${email}`);
              }
            }}>
            Whatsapp
          </InputButton>
          <InputButton backgroundColor="#04494B" borderRadius={10} onClick={
            async () => {
              if (!phoneNumber) {
                return;
              }
              let success = true;
              setLoading(true);
              console.log("phone number we are sending is ", phoneNumber);
              const data = await req(
                "POST",
                "/user/numberLogin",
                {
                  number: `+${phoneNumber.toLowerCase().trim()}`,
                  device: {
                    id: "web",
                    deviceToken: "MockToken",
                  },

                },
                async (message) => {
                  setLoading(false);
                  success = false;
                  setError(message);
                }
              );
              console.log("email is ", email);
              console.log(data?.user?.preferences);
              let preference = false;
              if (data?.user?.preferences) {
                preference = true;

              }

              if (!success) return;
              setLoading(false);
              if (email === '') {
                if (preference) {
                  navigate(`/patientVerifyEmail?number=${encodeURIComponent('+')}${phoneNumber}&preferences=${true}`);
                }
                else {
                  navigate(`/patientVerifyEmail?number=${encodeURIComponent('+')}${phoneNumber}`);
                }

              }
              else {
                navigate(`/patientVerifyEmail?email=${email}`);
              }

            }}>SMS</InputButton>
        </div>
        <div style={{ marginTop: 40, cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }} onClick={() => setButton(!buttons)}>
          <h4 style={{ fontFamily: 'Satoshi, sans-serif', color: '#120D30', fontSize: 15 }}>Otros métodos </h4>
          <img src={logo} alt="Logo" style={{ height: 10, width: 15, transform: buttons ? 'rotate(180deg)' : 'none', }} />
        </div>
        {buttons &&
          <>
            <div style={{ marginTop: 30, }}>
              <GoogleButton borderRadius={20} color={true} backgroundColor="#ffffff" GooglButtonIcon={GooglButtonIcon}>Continuar con Google </GoogleButton>
            </div>
            <div style={{ marginTop: 30, }}>
              <GoogleButton borderRadius={20} color={true} backgroundColor="#ffffff" GooglButtonIcon={EmailIcon} onClick={() => { setContinueWithEmail(true); setButton(false) }}>Continuar con Google </GoogleButton>
            </div>
          </>
        }
        <div style={{ marginTop: 70, justifyContent: 'center', alignItems: 'center', }}>
        <p style={{ marginRight: 5, fontFamily: 'Satoshi',fontSize:'16px',color:"#9E9CAA",textAlign:"center" }}>Al continuar, acepta automáticamente nuestros</p>
          <p style={{ fontFamily: 'Satoshi',textAlign:"center",fontSize:'16px',color:"#9E9CAA" }}><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(
            'https://kavan-assets.s3.eu-west-3.amazonaws.com/KAVAN+HEALTH+S.L.+-+TE%CC%81RMINOS+Y+CONDICIONES+GENERALES+-+PACIENTES+(28.01.2025).pdf',
            '_blank'
          )}> Términos & Condiciones</span>  y  <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => window.open(
            'https://kavan-assets.s3.eu-west-3.amazonaws.com/KAVAN_HEALTH_S.L._-_POL%C3%8DTICA_PRIVACIDAD_APP_(23.01.2024).pdf',
            '_blank'
          )}> Política de privacidad</span></p>
        </div>
      </div>
  
    </GoogleOAuthProvider>





  );
}


