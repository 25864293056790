import React, { useState } from "react";
import SignInHeading from "../../../components/SignInHeading";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { kavanLogoChatbot } from "../../../assets/index";
import BusinessSignIn from './businessSignIn';
import MyButtonGroup from "../../../components/ButtonGroup";
import SignIn from "./signIn";

const MainSignInScreen = () => {

    const [signInVisible,setSignInVisible] = useState(true);




    return (
        // <GoogleOAuthProvider clientId="557991807729-8v18jrd6su56i1dt1boiagrmp4n55nr7.apps.googleusercontent.com">
        <div style={{height:'90vh'}}>
            <div className="kavan__navbar-links_logo" style={{ marginLeft: 40, marginTop: 20 }}>
                <img src={kavanLogoChatbot} alt="Logo" onClick={() => window.location.href = "https://www.kavanhealth.com"} />
            </div>
            
            <div style={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
            <div className="mainDiv" style={{flex:0.3}}>
                <SignInHeading heading={"Bienvenido a Kavan Health"} subheading={"Ir a terapia puede ser tu mayor fortaleza."} />
                <div className="toggleButtonMain">
                <MyButtonGroup setSignInVisible={setSignInVisible}/>
                </div>
               
            </div>
            {signInVisible ? <SignIn/> : <BusinessSignIn dashboard={true}/>}
            </div>
            
        </div>
        // </GoogleOAuthProvider>
    );
}

export default MainSignInScreen;
