import React, { useEffect } from "react";
import { authReq } from "../requests";
import { getAllParams } from "../urlParams";


const useSaveData = () => {
    useEffect(() => {
        const obj = JSON.parse(JSON.stringify(getAllParams()));
        const keys = Object.keys(obj);
        console.log("OBJECT =>", obj);
        if (keys.length > 0)
            authReq("PATCH", "/user/updateProfile", obj).then((_) => console.log(_));
    }, []);
}


export default useSaveData;
