// import React, { useState } from "react";
// import './CustomPhoneIput.css'

// const CustomPhoneInput = () => {
//   const [selectedCode, setSelectedCode] = useState("+1");
//   const [phoneNumber, setPhoneNumber] = useState("");

//   const countryCodes = [
//     { code: "+1", country: "US" },
//     { code: "+91", country: "India" },
//     { code: "+44", country: "UK" },
//     { code: "+61", country: "Australia" },
    
//   ];

//   const handleCodeChange = (e) => {
//     setSelectedCode(e.target.value);
//   };

//   const handlePhoneChange = (e) => {
//     setPhoneNumber(e.target.value);
//   };

//   return (
//     <div style={{display:'flex',flexDirection:'column',}}>
//         <h3>Title of the text field</h3>
//         <div style={{display:'flex',flexDirection:'row',backgroundColor:"green",height:60,width:'100%'}}>
//             <div style={{display:'flex',backgroundColor:'red',flex:0.2,justifyContent:'center',alignItems:'center',}}>
//             <select
//         value={selectedCode}
//         onChange={handleCodeChange}
//         style={{}}
//       >
//         {countryCodes.map((code) => (
//           <option key={code.code} value={code.code}>
//             {code.code} ({code.country})
//           </option>
//         ))}
//       </select>
//             </div>
//             <div style={{display:'flex',backgroundColor:'red',flex:0.2,justifyContent:'center',alignItems:'center',}}>
//             <input
//         type="tel"
//         value={phoneNumber}
//         onChange={handlePhoneChange}
//         placeholder="Enter phone number"
        
//       />
//             </div>
            

//         </div>

//     </div>
//   );
// };

// export default CustomPhoneInput;


import React, { useState } from "react";
import InputButton from "../input_button/Button";
import './CustomPhoneIput.css'


const CustomPhoneInput = ({title,phonenumber,setphonenumber,selectedcode,setselectedcode,codeVisible,placeholder}) => {
  

  
  const handleCodeChange = (e) => {
    setselectedcode(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setphonenumber(e.target.value);
  };

  return (
    <div className="phoneIputMain">
      <h3 style={{fontSize:15,fontFamily: 'Satoshi, sans-serif',color:'#120D30'}}>{title}</h3>
      <div className="phone-input-wrapper">
       {/* {codeVisible&& <select
          value={selectedcode}
          onChange={handleCodeChange}
          className="dropdown"
        >
          {countryCodes.map((code) => (
            <option key={code.code} value={code.code}>
              {code.code} 
            </option>
          ))}
        </select>} */}
        <input
          type="tel"
          value={phonenumber}
          onChange={handlePhoneChange}
          placeholder={placeholder}
          className="phone-input"
        />
       
      </div>
    </div>
  );
};

export default CustomPhoneInput;
